import { FaNodeJs, FaReact } from "react-icons/fa";
import {
  SiCss3,
  SiFirebase,
  SiHtml5,
  SiJavascript,
  SiNextdotjs,
  SiVuedotjs,
  SiSass,
  SiTypescript,
  SiNuxtdotjs,
  SiRedux,
} from "react-icons/si";

export const technologySkills = [
  {
    icon: <SiHtml5 />,
    text: "HTML",
    color: "rgb(240, 101, 41)",
  },
  {
    icon: <SiCss3 />,
    text: "CSS",
    color: "rgb(41, 101, 241)",
  },
  {
    icon: <FaReact />,
    text: "React.js",
    color: "rgb(124, 197, 217)",
  },
  {
    icon: <SiNextdotjs />,
    text: "Next.js",
    color: "rgb(10, 10, 10)",
  },
  {
    icon: <SiVuedotjs />,
    text: "Vue.js",
    color: "rgb(63, 178, 127)",
  },
  {
    icon: <SiNuxtdotjs />,
    text: "Nuxt.js",
    color: "rgb(0, 220, 130)",
  },
  {
    icon: <SiSass />,
    text: "Sass",
    color: "rgb(204, 102, 153)",
  },
  {
    icon: <SiFirebase />,
    text: "Firebase",
    color: "rgb(246, 130, 13)",
  },
  {
    icon: <SiRedux />,
    text: "Redux",
    color: "rgb(115, 72, 183)",
  },
  {
    icon: <FaNodeJs />,
    text: "Node.js",
    color: "rgb(104, 160, 99)",
  },
];

export const languageSkills = [
  {
    icon: <SiJavascript />,
    text: "Javascript",
    color: "rgb(245, 222, 25)",
  },
  {
    icon: <SiTypescript />,
    text: "TypeScript",
    color: "rgb(45, 121, 199)",
  },
];
