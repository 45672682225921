import React from "react";
import ME from "../images/me.png";
import HannaMitriPDF from "../resume/Hanna Mitri.pdf";

const About = () => {
  return (
    <section id="about">
      <h2 data-aos="fade-up" data-aos-delay="100">
        About <span className="orange-color">Me.</span>
      </h2>
      <div
        data-aos="fade-up"
        data-aos-delay="250"
        className="section-border-bottom"
      ></div>

      <div className="about__wrapper">
        <div className="about-image" data-aos="fade-up" data-aos-delay="200">
          <img src={ME} alt="" />
        </div>

        <div className="about-text">
          <div data-aos="fade-up" data-aos-delay="300">
            Building <span className="orange-color">beautiful</span>,{" "}
            <span className="orange-color">customisable </span>
            and <span className="orange-color">responsive</span> user
            experiences is my passion. I enjoy pushing myself to solve problems
            and creating solutions that positively affect people.
          </div>

          <div data-aos="fade-up" data-aos-delay="400">
            Currently, I am a{" "}
            <span className="orange-color">Frontend Engineer</span> at Frontend
            Simplified and a private programming{" "}
            <span className="orange-color">tutor</span>.
          </div>

          <div data-aos="fade-up" data-aos-delay="450">
            Check out my resume!
          </div>

          <div data-aos="fade-up" data-aos-delay="450" className="about-button">
            <a href={HannaMitriPDF} target={"_blank"}>
              Resume
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
