import React from "react";
import KilljoyImage from "../images/projects/Killjoy7.jpeg";
import QHREImage from "../images/projects/QHRE.jpeg";
import EcommerceImage from "../images/projects/Ecommerce.jpeg";
import { FiLink } from "react-icons/fi";

const Projects = () => {
  return (
    <section id="projects">
      <div className="projects__section--wrapper">
        <h2
          className="projects__section--title"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Here Are Some Of My <span className="orange-color">Projects.</span>
        </h2>
        <ul className="project__list">
          <li className="project" data-aos="fade-up" data-aos-delay="300">
            <div className="project__wrapper">
              <img src={QHREImage} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">
                  Quality Homes Real Estate
                </h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, JavaScript, React, SimplyRETS API, Firebase
                </h4>
                <p className="project__description--para">
                  Quality Homes Real Estate is a real estate website that allows
                  a user to search for properties in a specific area. The user
                  can then view the details of the property and contact the
                  owner. The website uses the SimplyRETS API to retrieve the
                  data of the properties.
                </p>
                <div className="project__description--links">
                  <a
                    href="https://qualityhomesre.com"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li className="project" data-aos="fade-up" data-aos-delay="400">
            <div className="project__wrapper">
              <img src={KilljoyImage} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">Killjoy 7</h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, JavaScript, React, Firebase
                </h4>
                <p className="project__description--para">
                  Killjoy 7 is a web application that allows users to view teams
                  and their league standings. They can also participate in
                  events and benefit from the exclusive perks of being a member.
                </p>
                <div className="project__description--links">
                  <a
                    href="https://killjoy7.com"
                    className="project__description--link"
                    target={`_blank`}
                  >
                    <FiLink />
                  </a>
                </div>
              </div>
            </div>
          </li>

          <li className="project" data-aos="fade-up" data-aos-delay="500">
            <div className="project__wrapper">
              <img src={EcommerceImage} className="project__img" alt="" />
              <div className="project__wrapper--bg"></div>
              <div className="project__description">
                <h3 className="project__description--title">
                  E-Commerce React App
                </h3>
                <h4 className="project__description--sub-title">
                  Html, CSS, JavaScript, React
                </h4>
                <p className="project__description--para">
                  React E-commerce app that displays data about popular and
                  recommended books. Has the abililty to filter books and add
                  them to cart for purchase.
                </p>
                <div className="project__description--links">
                  {/* <a
                    href="https://github.com/davidbr4gg/davidbr4gg.github.io"
                    target="_blank"
                    className="project__description--link"
                  >
                    <i className="fab fa-github"></i>
                  </a> */}
                  <a
                    href="https://react-library1.firebaseapp.com/"
                    target="_blank"
                    className="project__description--link"
                  >
                    <FiLink />
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Projects;
