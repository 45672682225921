import React, { useRef } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  let clicks = 0;

  const sendEmail = (e) => {
    e.preventDefault();
    const nameCheck = document.getElementById("fromName").value.length;
    const emailCheck = document.getElementById("email").value.length;
    const messageCheck = document.getElementById("message").value.length;
    const formMessage = document.getElementById("form-message");

    if (clicks < 2) {
      if (nameCheck !== 0 && emailCheck > 12 && messageCheck !== 0) {
        formMessage.style.transform = "scale(1)";
        formMessage.innerHTML = "Message is being sent...";
        formMessage.style.fontStyle = "italic";

        emailjs
          .sendForm(
            "gmail",
            "template_dyvlfqm",
            form.current,
            "user_UCVQB9AIUbJaobUtpSXnz"
          )
          .then((result) => {
            if (result.text === "OK") {
              e.target.reset();
              clicks++;
              formMessage.innerHTML = "Success!! Message is sent";
              formMessage.style.fontStyle = "normal";

              setTimeout(() => {
                formMessage.style.transform = "scale(0)";
              }, 7500);
            }
          });
      } else {
        return;
      }
    } else {
      return;
    }
  };

  return (
    <section id="contact">
      <h2 data-aos="fade-up" data-aos-delay="100">
        Get in <span className="orange-color">Touch!</span>
      </h2>
      <div
        data-aos="fade-up"
        data-aos-delay="150"
        className="section-border-bottom"
      ></div>
      <div data-aos="fade-up" data-aos-delay="200" className="contact-wrapper">
        <form id="contact-form" ref={form} onSubmit={sendEmail}>
          <input
            required
            type="text"
            name="from_name"
            id="fromName"
            placeholder="Name"
          />
          <input
            required
            type="email"
            name="email"
            id="email"
            placeholder="Email"
          />
          <textarea
            required
            placeholder="Message"
            name="message"
            id="message"
          ></textarea>
          <button id="contact-button" type="submit">
            Send
          </button>

          <div id="form-message"></div>
        </form>
      </div>

      <div className="copyright">&#169; 2022 Hanna Mitri</div>
    </section>
  );
};

export default Contact;
