import React, { useEffect, useState } from "react";
import * as THREE from "three";
// import { Link, animateScroll as scroll } from "react-scroll";
import { AiFillFilePdf, AiFillLinkedin, AiFillMail } from "react-icons/ai";
import { Link } from "react-scroll";
import { MdOutlineModeNight } from "react-icons/md";
import { BsBrightnessHigh } from "react-icons/bs";

const Landing = () => {
  const [toggleTheme, setToggleTheme] = useState(false);

  toggleTheme
    ? (document.body.classList += " toggle__theme")
    : document.body.classList.remove("toggle__theme");

  useEffect(() => {
    const canvas = document.querySelector("canvas.webgl");

    const scene = new THREE.Scene();

    let mouseX = 0,
      mouseY = 0,
      windowHalfX = window.innerWidth / 2,
      windowHalfY = window.innerHeight / 2;
    const spheres = [];

    document.addEventListener("mousemove", onDocumentMouseMove);

    const geometry = new THREE.TetrahedronGeometry(3);
    const material = new THREE.MeshPhongMaterial({
      specular: 0x333333,
      shininess: 100,
      combine: THREE.MixOperation,
      reflectivity: 0.4,
    });

    var light1 = new THREE.DirectionalLight(0x7342d6, 1.5);
    light1.position.set(100, 0, 0);
    scene.add(light1);

    var light2 = new THREE.DirectionalLight(0x7342d6, 1.5);
    light2.position.set(-100, 0, 0);
    scene.add(light2);

    const meshT = {
      triangles: 75,
    };

    for (let i = 0; i < meshT.triangles; i++) {
      const meshScale = Math.random() * 0.35 * 0.7;
      const mesh = new THREE.Mesh(geometry, material);

      mesh.position.x = Math.random();
      mesh.position.y = Math.random();
      mesh.position.z = Math.random() * 50;
      mesh.scale.set(meshScale, meshScale, meshScale);

      scene.add(mesh);

      spheres.push(mesh);
    }

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    window.addEventListener("resize", () => {
      windowHalfX = window.innerWidth / 2;
      windowHalfY = window.innerHeight / 2;

      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    const camera = new THREE.PerspectiveCamera(
      60,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.z = 25;
    scene.add(camera);

    function onDocumentMouseMove(event) {
      mouseX = (event.clientX - windowHalfX) / 100;
      mouseY = (event.clientY - windowHalfY) / 100;
    }

    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const clock = new THREE.Clock();

    const tick = () => {
      const timer = 0.0001 * Date.now();

      camera.position.x += (mouseX - camera.position.x) * 0.1;
      camera.position.y += (-mouseY - camera.position.y) * 0.1;

      camera.lookAt(scene.position);

      for (let i = 0, il = spheres.length; i < il; i++) {
        const sphere = spheres[i];

        sphere.position.x = 10 * Math.cos(timer + i);
        sphere.position.y = 5 * Math.sin(timer + i * 1.1);

        sphere.rotation.x = 5 * Math.cos(timer + i);
        sphere.rotation.y = 5 * Math.sin(timer + i * 1.1);
      }

      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();
  }, []);

  return (
    <>
      <canvas className="webgl"></canvas>

      <nav className="navbar__wrapper">
        <ul className="list__nav--wrapper">
          <li>
            <Link to="about">About Me</Link>
          </li>
          <li>
            <Link to="skills">My Skills</Link>
          </li>
          <li>
            <Link to="projects">Projects</Link>
          </li>
          <li>
            <Link to="contact">Contact</Link>
          </li>
          <li>
            <button
              className="theme__icon"
              onClick={() =>
                toggleTheme ? setToggleTheme(false) : setToggleTheme(true)
              }
            >
              {toggleTheme ? <BsBrightnessHigh /> : <MdOutlineModeNight />}
            </button>
          </li>
        </ul>
      </nav>

      <section id="summary">
        <div className="summary-position">
          <h1 data-aos="fade-up" data-aos-delay="200">
            Hey I'm <span className="orange-color">Hanna.</span>
          </h1>
          <div data-aos="fade-up" data-aos-delay="400" className="summary-text">
            I'm an aspiring{" "}
            <span className="orange-color">Frontend Software Engineer </span>
            passionate about building web applications with great user
            experiences.
          </div>

          <div className="svg__wrapper" data-aos="fade-up" data-aos-delay="600">
            <div>
              <a
                href="https://www.linkedin.com/in/hannamitri/"
                target={`_blank`}
              >
                <AiFillLinkedin />
              </a>
            </div>
            <div>
              <a href="mailto:hannagmitri7@hotmail.com" target={`_blank`}>
                <AiFillMail />
              </a>
            </div>
          </div>
        </div>

        <Link className="scroll-downs" to="about">
          <div data-aos="fade-down" data-aos-delay="800">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </Link>
      </section>
    </>
  );
};

export default Landing;
