import React from "react";
import { technologySkills, languageSkills } from "../skillsData";

const Skills = () => {
  return (
    <section id="skills">
      <h2 data-aos="fade-up" data-aos-delay="200">
        My <span className="orange-color">Skill Stack.</span>
      </h2>
      <div
        data-aos="fade-up"
        data-aos-delay="350"
        className="section-border-bottom"
      ></div>

      <div className="button-wrapper">
        <div>
          <h3 data-aos="fade-up" data-aos-delay="200">
            <span className="orange-color">Technologies </span>I've used
          </h3>
          <div className="technology-buttons">
            {technologySkills.map((item, index) => (
              <div
                key={index}
                data-aos="fade-up"
                data-aos-delay="200"
                className={`skill__buttons ${
                  (item.text === "three.js" && " threejs__class") ||
                  (item.text === "next.js" && " threejs__class")
                }`}
                style={{ color: item.color }}
              >
                {item.icon}
                <div className="skills__button--d-block">{item.text}</div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h3 data-aos="fade-up" data-aos-delay="200">
            <span className="orange-color">Languages </span>I've worked with
          </h3>
          <div className="language-buttons">
            {languageSkills.map((item, index) => (
              <div
                key={index}
                data-aos="fade-up"
                data-aos-delay="200"
                className="skill__buttons"
                style={{ color: item.color }}
              >
                {item.icon}
                <div className="skills__button--d-block">{item.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
